.TopCell {
  border-top: 1px solid white !important;
  border-bottom: 0px !important;
}

.BottomCell {
  border-top: 0px !important;
  border-bottom: 1px solid white !important;
  border-top: 0.5px solid white;
}

td {
  padding: 6px !important;
  border-right: 1px solid grey;
  color: white !important;
  background-color: #121212;
  font-size: 1rem !important;
}

.Channel {
  background-color: #90caf9;
}

.DropDown {
  background-color: white;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  background-color: #121212;
}

.flex {
  display: flex;
}

tr {
  display: flex !important;
}

.FirstColumn {
  color: black !important;
}

.TableWidth900 {
  max-width: 900px;
}

td:nth-child(1) {
  flex-basis: 80px;
}

td:nth-child(3) {
  flex-basis: 25%;
  flex-grow: 1;
}

td:nth-child(4) {
  flex-basis: 40px;
}

td:nth-child(5) {
  flex-basis: 75px;
  text-align: center;
}

td:nth-child(6) {
  flex-basis: 85px;
}

td:nth-child(7) {
  flex-basis: 70px;
  flex-grow: 1;
}

.ScheduleLogo {
  height: 25px;
}

.LogoCell {
  border-right: 0;
}

.FinishedGameFinal {
  flex-basis: 87px !important;
}

.Date {
  flex-basis: 226px !important;
}

.LiveBottom:nth-child(1) {
  flex-basis: 80px;
}

.LiveBottom:nth-child(2) {
  text-align: center;
  flex-basis: 55%;
  flex-grow: 1;
}

.LiveBottom:nth-child(3) {
  padding-right: 5px;
  flex-grow: 0;
  flex-basis: 50px;
  padding-right: 6px !important;
}

.WideLink {
  display: flex;
  width: 100%;
}

.LiveScore:nth-child(4) {
  flex-basis: 40px;
  text-align: center;
}

.FinishedGameBoxScore {
  flex-basis: 73px !important;
}

@media only screen and (max-width: 900px) {
  td {
    padding-left: 2px !important;
    padding-right: 0px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    font-size: 0.875rem !important;
  }

  td:nth-child(1) {
    flex-basis: 50px;
    flex-shrink: 0;
  }

  td:nth-child(3) {
    flex-basis: 55%;
    flex-grow: 1;
  }

  td:nth-child(4) {
    flex-grow: 0;
  }

  td:nth-child(5) {
    flex-basis: 80px;
    text-align: center !important;
  }

  td:nth-child(6) {
    flex-basis: 60px;
    justify-content: center;
    flex-shrink: 0;
  }

  .LiveScore:nth-child(5) {
    flex-basis: 50px;
  }

  .ScheduleLogo {
    height: 25px;
  }

  .LogoCell {
    padding-right: 6px !important;
  }

  .FinishedGameBoxScore {
    flex-basis: 35px !important;
  }

  .Date {
    flex-basis: 173px !important;
  }
}

.HeaderDiv {
  display: flex;
  justify-content: center;
  background-color: #2d2d2d;
  align-items: center;
}

h1 {
  color: white;
}

.ButtonDiv {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.SubButtonDiv {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.TopButton {
  flex-grow: 1;
  background-color: #ffab91 !important;
  height: 40px;
  border: #121212 1px solid !important;
}

.SelectedButton {
  background-color: #ffddc1 !important;
}

.LiveBottom {
  border: 0px solid grey !important;
}

.LiveMiddle {
  border-bottom: 0px !important;
}

.LiveBottomRow {
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  border-top: 1px solid grey !important;
}

.NoRightBorder {
  border-right: 0px !important;
}

.Redzone {
  border: 1px solid red;
}

a:-webkit-any-link {
  color: white;
}

.HideLink {
  text-decoration: none;
}

.Win {
  background-color: green;
}

.Loss {
  background-color: red;
}

.Pointer {
  cursor: pointer;
}

.Tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

/* Tooltip text */
.Tooltip .TooltipText {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}

.Tooltip:hover .TooltipText {
  visibility: visible;
}

.DetailedGameBottom {
  flex-basis: 100% !important;
  text-align: center !important;
}

.DetailedGameScore {
  flex-basis: 30px;
  text-align: center;
}

.DetailedGame:nth-child(1) {
  flex-basis: 70px;
}

.DetailedGame:nth-child(3) {
  flex-grow: 1;
}

.DetailedGameRow {
  /*max-width: 700px;*/
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.DetailedGameScorePossession {
  flex-basis: 70px !important;
  text-align: center;
}

.DetailedGameTable {
  /*width: 95% !important*/
  max-width: 1200px;
  margin-right: 2.5%;
  margin-left: 2.5%;
}

.CenterDiv {
  justify-content: center;
  display: flex;
}

@media only screen and (min-width: 901px) {
  .DetailedGame {
    font-size: 20px;
  }

  .DetailedGame:nth-child(1) {
    flex-basis: 85px;
  }
}

.FieldDiv {
  padding-top: 20px;
}

.FieldTable {
  max-width: 1200px;
  max-height: 442px;
}

.Field {
  height: 200px;
  background-color: green;
  flex-basis: 0px !important;
  padding: 0 !important;
}

.Yard {
  flex-grow: 1;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  border-right: 0;
  overflow: visible;
}

.TenYards {
  border-left: 0.5px solid white;
}

.FirstDownAway {
  border-left: 1px solid yellow;
}

.FirstDownHome {
  border-left: 1px solid yellow;
}

.LineOfScrimmage {
  border-left: 1px solid blue;
}

.EndYard {
  border-right: 0.5px solid white;
}

.TopBorder {
  border-top: 1px solid white !important;
}

.FieldImg {
  height: 50px;
}

.HomeImg {
  transform: rotate(90deg);
}

.AwayImg {
  transform: rotate(270deg);
}

.Endzone {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 10;
  border: 2px solid white;
}

.FieldPossession {
  position: absolute;
  height: 40px;
  padding-top: 80px;
  left: -20px;
}

.FieldText {
  position: absolute;
}

.FieldImageHolder {
  position: relative;
}

.DetailedGameAdvancedCell {
  flex-basis: 50% !important;
  border: 0 !important;
}

.DetailedGameAdvancedCell:nth-child(2) {
  text-align: center;
}

.SortButton {
  position: absolute;
  right: 20px;
  top: 25px;
}

.SelectedMenuItem {
  background-color: #ffab91 !important;
}

.Position {
  flex-basis: 40px !important;
  text-align: center !important;
  border: 0.5px solid white;
  padding-left: 0px !important;
  padding-right: 0px !important;
  flex-grow: 0 !important;
}

.PreviewName {
  flex-basis: 1px !important;
  text-align: center !important;
  border: 0.5px solid white;
  padding-left: 0px !important;
  padding-right: 0px !important;
  flex-grow: 1 !important;
  flex-shrink: 1 !important;
}

.Grade {
  flex-basis: 40px !important;
  text-align: center !important;
  border: 0.5px solid white;
  padding-left: 0px !important;
  padding-right: 0px !important;
  flex-grow: 0 !important;
}

.Attempts {
  flex-basis: 45px !important;
  text-align: center !important;
  border: 0.5px solid white;
  padding-left: 0px !important;
  padding-right: 0px !important;
  flex-grow: 0 !important;
}

.Rivalry {
  border-right: 1px solid goldenrod !important;
}

.DivisionHeader {
  width: 100%;
  text-align: center;
}

.StandingsDiv {
  flex-wrap: wrap;
}

.StandingsLogo {
  flex-basis: 0px !important;
}

.Record {
  flex-basis: 50px !important;
  flex-grow: 0 !important;
  text-align: center !important;
}

.FPI {
  flex-basis: 60px !important;
  flex-grow: 0 !important;
}

.TeamName {
  flex-basis: 25% !important;
}

.Percentage {
  flex-basis: 85px !important;
  flex-grow: 0 !important;
}

.StandingsHeader {
  font-size: 0.7em !important;
  text-align: center !important;
}

.TeamNameHeader {
  flex-basis: 29.5% !important;
}

@media only screen and (max-width: 900px) {
  .Position {
    flex-basis: 25px !important;
  }

  .Grade {
    flex-basis: 30px !important;
  }

  .Attempts {
    flex-basis: 30px !important;
  }
}
